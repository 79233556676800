<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    spacer
    v-model="isSidebarActiveLocal"
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>
        {{ Object.entries(this.data).length === 0 ? "ADD NEW" : "UPDATE" }} ITEM
      </h4>
      <feather-icon
        icon="XIcon"
        @click.stop="isSidebarActiveLocal = false"
        class="cursor-pointer"
      ></feather-icon>
    </div>

    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar
      class="scroll-area--data-list-add-new"
      :settings="settings"
    >
      <div class="p-6">
        <!-- NAME -->
        <vs-input
          :label="$t('NameEn')"
          v-model="NameEN"
          class="mt-5 w-full"
          name="nameen"
          v-validate="'required'"
        />
        <span class="text-danger text-sm" v-show="errors.has('nameen')">{{
          errors.first("name_en")
        }}</span>

        <vs-input
          :label="$t('NameAr')"
          v-model="NameAR"
          class="mt-5 w-full"
          name="namear"
          v-validate="'required'"
        />
        <span class="text-danger text-sm" v-show="errors.has('namear')">{{
          errors.first("name_ar")
        }}</span>
      </div>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid">{{
        $t("Save")
      }}</vs-button>
      <vs-button
        type="border"
        color="danger"
        @click="isSidebarActiveLocal = false"
        >{{ $t("Cancel") }}</vs-button
      >
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { domain } from "@/gloabelConstant.js";
export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    }
  },

  watch: {
    isSidebarActive(val) {
      if (!val) return;
      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        let { ID, NameEN, NameAR } = JSON.parse(JSON.stringify(this.data));
        this.ID = ID;
        this.NameEN = NameEN;
        this.NameAR = NameAR;
      }
      // Object.entries(this.data).length === 0 ? this.initValues() : { this.ID, this.Name, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
    }
  },

  data() {
    return {
      baseURL: domain,
      uploadUrl: domain + "API/Common/UploadFile",
      ID: null,
      NameEN: "",
      NameAR: "",
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      }
    };
  },

  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar");
        }
      }
    },
    isFormValid() {
      return !this.errors.any() && this.NameEN;
    }
  },

  methods: {
    initValues() {
      if (this.data.ID) return;
      this.ID = null;
      this.NameAR = "";
      this.NameEN = "";
    },

    submitData() {
      this.$validator.validateAll().then(result => {
        if (result) {
          const obj = {
            ID: this.ID,
            NameEN: this.NameEN,
            NameAR: this.NameAR
          };

          if (this.ID !== null && this.ID >= 0) {
            this.$store
              .dispatch("ReportTypeList/UpdateReportType", obj)
              .catch(err => {
                console.error(err);
                window.showError(err.response.data.message);
              });
          } else {
            delete obj.ID;
            obj.popularity = 0;
            this.$store.dispatch("ReportTypeList/AddReportType", obj).catch(err => {
              console.error(err);
              window.showError(err.response.data.message);
            });
          }

          this.$emit("closeSidebar");
          this.initValues();
          //this.$store.dispatch("foodTypeList/fetchfoodTypeListItems");
        }
      });
    }
  },

  components: {
    VuePerfectScrollbar
  }
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  height: calc(100% - 4.3rem);
}
</style>
